import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row, config, Hidden } from "react-awesome-styled-grid"
import styled from "styled-components"
import Layout from "../../components/Layout"
import { Heading2 } from "../../components/Heading"
import Block from "../../components/Block"

import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { FaStarHalfAlt } from "@react-icons/all-files/fa/FaStarHalfAlt"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"
import { ArrowButton, HoverButton, TrackedLink } from "../../components/Button"
import DivHelper from "../../components/DivHelper"
import { TableOfContents } from "../../components/TableOfContents"
import { IconCheck, IconCross } from "../../components/Icons"
import { Collapse } from "react-collapse"

const TableWrapper = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  user-select: none;
  font-size: 0.9rem;
  border: none;
  outline: none;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  width: 800px;

  th,
  td {
    padding: 15px 10px;
    background-color: #fff;
  }

  th {
    hyphens: auto;
    text-align: left;
    width: 100px;
    position: sticky;
    left: 0px;
  }

  tr:nth-child(even) > th,
  tr:nth-child(even) > td {
    background-color: #d9ebfc;
  }

  td {
    width: auto;
  }

  @media (min-width: 576px) {
    th {
      width: 150px;
    }
  }

  @media (min-width: 768px) {
    th {
      width: 175px;
    }
  }

  @media (min-width: 992px) {
    width: 1000px;

    th {
      width: 200px;
    }
  }

  @media (min-width: 1200px) {
    width: 1190px;

    th {
      width: 250px;
    }
  }
`

const TdCenter = styled.td`
  text-align: center;
`

const StyledRating = styled.div`
  font-size: 1.2rem;
  color: #ffbb00;
`

const WinnerBox = styled(Row)`
  border-radius: 4px;
  border: 5px solid
    ${props => (props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7")};
`

const WinnerHighlight = styled(Col)`
  background: ${props =>
    props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7"};
  text-align: center;
  color: #000;

  p {
    text-align: center;
  }
`

const ContentWrapper = styled.div`
  padding: 15px;
`

const Paragraph = styled.p`
  ${props => props.m0 && "margin-bottom: 0;"}
  ${props =>
    props.right &&
    config(props).media["md"]`
    text-align: right;
  `}
`

const ColoredLink = styled.a`
  background-color: ${props => props.color};
`

const ProductHeadingWrapper = styled.div`
  ${props => props.desktop && "display: none;"}
  text-align: center;
  margin-bottom: 15px;

  ${props => config(props).media["md"]`
    display: ${props => (props.desktop ? "block" : "none")};
  `}
`

const StyledProductHeading = styled.h3`
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 15px;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    width: 85%;
    height: 1px;
    bottom: -10px;
    transform: translateX(-50%);
    background: #000;
  }
`

const SeparatorLine = styled.div`
  width: ${props => (props.small ? 50 : 75)}%;
  height: 2px;
  background: #666;
  margin: ${props => (props.small ? 0 : 100)}px auto 0 auto;
`

const DateRight = styled.p`
  text-align: right;
`

const Quote = styled.p`
  text-align: center;
  font-style: italic;
`

const RowMargin = styled(Row)`
  margin-top: ${props => props.margin || 20}px;
`

const BackgroundBeige = styled.div`
  background-color: #f0f0f0;
  margin-top: 40px;
  padding: 20px 0 40px 0;
`

const ImageWrapper = styled.div`
  padding: 30px;

  picture,
  img {
    border-radius: 50%;
  }
`

const ColMarginMobile = styled(Col)`
  margin-top: 20px;
  ${props => config(props).media["xl"]`
    margin-top: 0px;
  `}
`

const RotatedSpan = styled.span`
  display: inline-block;
  transform: rotate(-90deg);
`

const Heading3Margin = styled.h3`
  margin-bottom: 10px;
`

const SmallText = styled.i`
  color: #aaa;
`

const ProductHeading = ({ rating, desktop = false, children }) => {
  return (
    <ProductHeadingWrapper desktop={desktop}>
      <StyledProductHeading>{children}</StyledProductHeading>
      <div>
        <Rating rating={rating} />
      </div>
    </ProductHeadingWrapper>
  )
}

const Rating = ({ rating }) => {
  const stars = Math.floor(rating)
  const halfStar = rating % 1 > 0

  return (
    <StyledRating>
      {[...Array(stars)].map(i => (
        <FaStar key={i} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </StyledRating>
  )
}

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 400;
`

const EmojiListItem = styled.li`
  padding-left: 1.5rem;
  text-indent: -1.5rem;

  &::before {
    width: 1.5rem;
    content: "${props => props.emoji} ";
  }
`

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "stock/color.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data.image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const CollapseWrapper = styled.div`
  & .ReactCollapse--collapse {
    transition: height 300ms;
  }
`

const CbdCremes = ({ location }) => {
  const [detailOpen, setDetailOpen] = useState(false)

  return (
    <Layout
      title="CBD Cremes und Salben Vergleich"
      description="Sie möchten CBD Cremes bzw. Salben kaufen? Unsere Erfahrungen und der Preisvergleich zeigen Ihnen die besten Cannabis Produkte im Test. Jetzt informieren über Kosten und Rabatte."
      location={location}
      comments={true}
      light
      mt0
    >
      <Hero heightVh={30}>
        <Background>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>CBD Cremes und Salben</HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Row>
          <Col>
            <TableWrapper>
              <Table cellSpacing={0} cellPadding={0} id="vergleichstabelle">
                <tr>
                  <th aria-label="Produktbild"></th>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-cremes/hanfgefluester.png"
                      alt="Hanfgeflüster CBD Balsam"
                      layout="constrained"
                      placeholder="blurred"
                      width={200}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-cremes/wobecare.png"
                      alt="Wobecare CBD Aktiv Creme"
                      layout="constrained"
                      placeholder="blurred"
                      width={200}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-cremes/cbds-finest.png"
                      alt="CBD's Finest Liposomale PEA Salbe"
                      layout="constrained"
                      placeholder="blurred"
                      width={200}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-cremes/weedm.png"
                      alt="weedM CBD Creme"
                      layout="constrained"
                      placeholder="blurred"
                      width={200}
                    />
                  </TdCenter>
                </tr>
                <tr>
                  <th aria-label="Kauf Button"></th>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                      category="cbd-cremes"
                      action="hanfgefluester"
                      label="table-top"
                    >
                      Hier günstig kaufen
                      <img
                        src="https://t.adcell.com/p/view?promoId=297834&slotId=98699"
                        border="0"
                        width="1"
                        height="1"
                        alt=""
                      />
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton href="#wobecare" internal>
                      Zum Testbericht
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton href="#cbdsfinest" internal>
                      Zum Testbericht
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton href="#weedm" internal>
                      Zum Testbericht
                    </HoverButton>
                  </TdCenter>
                </tr>
                <tr>
                  <th>Produkt</th>
                  <td>CBD Balsam</td>
                  <td>Wobecare CBD Aktiv Creme</td>
                  <td>Liposomale PEA Salbe</td>
                  <td>CBD Creme</td>
                </tr>
                <tr>
                  <th>CBD Salben/Cremes Test</th>
                  <td>
                    <Rating rating={5} />
                  </td>
                  <td>
                    <Rating rating={3.5} />
                  </td>
                  <td>
                    <Rating rating={3} />
                  </td>
                  <td>
                    <Rating rating={1} />
                  </td>
                </tr>
                <tr>
                  <th>CBD Salben/Cremes Preis</th>
                  <td>
                    37,95 €<br />
                    <SmallText>(3.795,00 €/l)</SmallText>
                  </td>
                  <td>
                    24,95 €<br />
                    <SmallText>(2.495,00 €/l)</SmallText>
                  </td>
                  <td>
                    34,90 €<br />
                    <SmallText>(3.490,00 €/l)</SmallText>
                  </td>
                  <td>
                    49,90 €<br />
                    <SmallText>(4.990,00 €/l)</SmallText>
                  </td>
                </tr>
                <tr>
                  <th>Anbieter</th>
                  <td>
                    Hanfgeflüster
                    <br />
                    (aus Deutschland)
                  </td>
                  <td>
                    Mucos Pharma GmbH
                    <br />
                    (aus Deutschland)
                  </td>
                  <td>
                    CBDs Finest
                    <br />
                    (aus Deutschland)
                  </td>
                  <td>
                    weedM
                    <br />
                    (aus Österreich)
                  </td>
                </tr>
                <tr>
                  <th>Packungsgröße</th>
                  <td>100 ml</td>
                  <td>100 ml</td>
                  <td>50 ml</td>
                  <td>50 ml</td>
                </tr>
                <tr>
                  <th>CBD Gehalt pro 100 ml</th>
                  <td>500 mg</td>
                  <td>500 mg</td>
                  <td>500 mg</td>
                  <td>300 mg</td>
                </tr>
                <tr>
                  <th>CBD Zertifikat</th>
                  <td>TÜV Süd</td>
                  <td>Unbekannt</td>
                  <td>TÜV Süd</td>
                  <td>Unbekannt</td>
                </tr>
                <tr>
                  <th>CBD Salben/Cremes Wirkung</th>
                  <td>
                    👍
                    <br />
                    Sehr gut
                  </td>
                  <td>
                    👍
                    <br />
                    Gut
                  </td>
                  <td>
                    👍
                    <br />
                    Gut
                  </td>
                  <td>
                    👎
                    <br />
                    Schlecht
                  </td>
                </tr>
                <tr>
                  <th>Zusätze</th>
                  <td>Rosmarin, Kampfer, Menthyllactat</td>
                  <td>
                    Arnika, Beinwell, Menthol, Kampfer, Aloe Vera, Brokkoliöl
                  </td>
                  <td>Keine</td>
                  <td>Keine</td>
                </tr>
                <tr>
                  <th>Hauttyp</th>
                  <td>
                    Alle
                    <br />
                    (Angenehmes Hautgefühl)
                  </td>
                  <td>
                    Normale Haut
                    <br />
                    (Angenehmes Hautgefühl)
                  </td>
                  <td>
                    Normale Haut
                    <br />
                    (Angenehmes Hautgefühl)
                  </td>
                  <td>
                    Nicht für Allergiker
                    <br />
                    (Rötungen auf der Haut)
                  </td>
                </tr>
                <tr>
                  <th>Dermatologisch getestet</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Zieht schnell ein/fettet nicht</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Ohne Duftstoffe</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Ohne Farbstoffe</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Ohne Parabene</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>Vegetarisch und Vegan</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                </tr>
                <tr>
                  <th>Hinweise</th>
                  <td>
                    <UnstyledList>
                      <EmojiListItem emoji="☀️">Wärmend</EmojiListItem>
                      <EmojiListItem emoji="💧">
                        Spendet Feuchtigkeit
                      </EmojiListItem>
                      <EmojiListItem emoji="💪">
                        Erhöht die Regenerationsfähigkeit
                      </EmojiListItem>
                    </UnstyledList>
                  </td>
                  <td>
                    <UnstyledList>
                      <EmojiListItem emoji="💧">
                        Kühlt und entspannt
                      </EmojiListItem>
                      <EmojiListItem emoji="📺">
                        Bekannte CBD Salbe
                      </EmojiListItem>
                      <EmojiListItem emoji="🌳">
                        Ökologische Verpackung
                      </EmojiListItem>
                    </UnstyledList>
                  </td>
                  <td>
                    <UnstyledList>
                      <EmojiListItem emoji="🌳">Bio Hanf</EmojiListItem>
                      <EmojiListItem emoji="🧪">
                        Verschiedene CBD Stärken vorhanden
                      </EmojiListItem>
                    </UnstyledList>
                  </td>
                  <td>
                    <UnstyledList>
                      <EmojiListItem emoji="❌">
                        Fettet auf der Haut
                      </EmojiListItem>
                      <EmojiListItem emoji="❌">
                        Kleine Packung für hohen Preis
                      </EmojiListItem>
                    </UnstyledList>
                  </td>
                </tr>
                <tr>
                  <th aria-label="Kauf Button"></th>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                      category="cbd-cremes"
                      action="hanfgefluester"
                      label="table-bottom"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton href="#wobecare" internal>
                      Zum Testbericht
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton href="#cbdsfinest" internal>
                      Zum Testbericht
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton href="#weedm" internal>
                      Zum Testbericht
                    </HoverButton>
                  </TdCenter>
                </tr>
              </Table>
            </TableWrapper>
          </Col>
        </Row>
        <Block>
          <Heading2>Übersicht</Heading2>
          <Row>
            <Col>
              <TableOfContents>
                <li>
                  <a href="#vergleichstabelle">Vergleichstabelle</a>
                </li>
                <li>
                  <a href="#empfehlungen">
                    CBD Cremes und Salben für den Körper Testsieger 2023: Unsere
                    Erfahrungen
                  </a>
                  <ol>
                    <li>
                      <ColoredLink color="#58cb53f7" href="#hohe-empfehlung">
                        Hohe CBD Cremes und Salben Empfehlung
                      </ColoredLink>
                    </li>
                    <li>
                      <ColoredLink color="#fdff61" href="#mittlere-empfehlung">
                        Mittlere CBD Cremes und Salben Empfehlung
                      </ColoredLink>
                    </li>
                    <li>
                      <ColoredLink color="#ec5d5d" href="#geringe-empfehlung">
                        Geringe bzw. keine CBD Salben/Cremes Empfehlung
                      </ColoredLink>
                    </li>
                  </ol>
                </li>
                <li>
                  <a href="#wissenswertes">
                    Wissenswertes zu Cremes und Salben
                  </a>
                  <ol>
                    <li>
                      <a href="#wirkung">Wie gut wirkt CBD Creme bzw. Salbe?</a>
                    </li>
                    <li>
                      <a href="#wie-wirkt">
                        Wie wirkt die CBD Creme bzw. Salbe?
                      </a>
                    </li>
                    <li>
                      <a href="#haeufigkeit">
                        Wie oft soll man CBD Creme bzw. Salbe verwenden?
                      </a>
                    </li>
                    <li>
                      <a href="#symptome">
                        Welche Symptome lassen sich mit einer CBD Creme
                        behandeln?
                      </a>
                    </li>
                  </ol>
                </li>
                <li>
                  <a href="#klinische-studie">Untersuchung der Wirksamkeit</a>
                </li>
                <li>
                  <a href="#positive-eigenschaften">
                    Was sind positive Eigenschaften von CBD Cremes und Salben?
                  </a>
                </li>
                <li>
                  <a href="#richtige-creme-finden">
                    Wie findet man für sich die richtige CBD Creme bzw. Salbe?
                  </a>
                </li>
              </TableOfContents>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="empfehlungen">
            CBD Cremes und Salben für den Körper Testsieger 2023: Unsere
            Erfahrungen
          </Heading2>
        </Block>
        <Block>
          <Heading2 id="hohe-empfehlung" block small>
            Hohe CBD Cremes und Salben Empfehlung
          </Heading2>
          <WinnerBox align="stretch">
            <WinnerHighlight xs={12} md={6} lg={4} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5}>
                  CBD Balsam von Hanfgeflüster: Testsieger mit 5 Sternen und
                  hochwertigen Inhaltsstoffen
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                  category="cbd-cremes"
                  action="hanfgefluester"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-cremes/hanfgefluester.png"
                    alt="Hanfgeflüster CBD Balsam"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>☀️ Wärmend</p>
                <p>💧 Feuchtigkeitsspendend</p>
                <p>💪 Erhöht die Regenerationsfähigkeit</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                    width={14}
                    category="cbd-cremes"
                    action="hanfgefluester"
                    label="bullet"
                  >
                    Jetzt kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5} desktop>
                  CBD Balsam von Hanfgeflüster: Testsieger mit 5 Sternen und
                  hochwertigen Inhaltsstoffen
                </ProductHeading>
                <p>
                  Der{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                    category="cbd-cremes"
                    action="hanfgefluester"
                    label="text"
                  >
                    CBD Balsam von Hanfgeflüster
                  </TrackedLink>{" "}
                  hat im Vergleichstest die Höchstnote von 5 Sternen erhalten.
                  Der Anbieter Hanfgeflüster steht für hochwertige Produkte{" "}
                  <strong>"Made in Germany"</strong>. Der CBD Balsam ist ein
                  Testsieger und empfehlenswert für alle, die sich natürlich und
                  wirksam unterstützen möchten.
                </p>
                <p>
                  Die Packung des CBD Balms von Hanfgeflüster enthält 100 ml
                  Inhalt und hat einen <strong>CBD Gehalt von 500 mg</strong>{" "}
                  pro Packung. Der Wirkgehalt des CBD wurde durch den{" "}
                  <a
                    href="https://www.tuvsud.com/de-de"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    TÜV Süd
                  </a>{" "}
                  geprüft und bestätigt. Die subjektive Wahrnehmung der{" "}
                  <strong>Wirkung des CBD Balms ist sehr gut</strong>, wodurch
                  es sich als eine wirksame Unterstützung für die tägliche
                  Anwendung eignet. Rosmarin, Kampfer und Menthyllactat veredeln
                  das CBD Balsam im Produktvergleich.
                </p>
                <p>
                  Der CBD Balsam von Hanfgeflüster eignet sich für alle
                  Hauttypen und hinterlässt ein angenehmes Hautgefühl. Der
                  Balsam wurde <strong>dermatologisch getestet</strong> und ist
                  besonders <strong>hautfreundlich</strong>, da er schnell
                  einzieht und nicht fettet. Zudem enthält er{" "}
                  <strong>keine Duftstoffe, Farbstoffe oder Parabene</strong>.
                  Darüber hinaus ist er vegetarisch und vegan.{" "}
                </p>
                <p>
                  Die getestete CBD Creme für den Körper ist ein wahrer{" "}
                  <strong>Preis- und Testsieger und kostet 37,95 €</strong>.
                  Eines der besonderen Highlights des Balsams ist seine wärmende
                  Wirkung, die besonders bei Verspannungen hilfreich sein kann.
                  Er spendet der Haut auch Feuchtigkeit und erhöht ihre
                  Regenerationsfähigkeit. Mit seiner natürlichen und sicheren
                  Formel ist er eine hervorragende Wahl für die mehrmalige
                  tägliche Anwendung.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                    width={18}
                    category="cbd-cremes"
                    action="hanfgefluester"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block id="naturecan">
          <Heading2 id="mittlere-empfehlung" block>
            CBD Cremes und Salben Erfahrungen & Vergleich - inkl. Wobecare CBD
            Aktiv Creme
          </Heading2>
          <Heading2 id="mittlere-empfehlung" block small>
            Mittlere CBD Cremes und Salben Empfehlung
          </Heading2>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={4}>
                  Naturecan CBD Salbe für den Körper: 4 Sterne bei
                  Vergleichstest mit Bio-Hanf und ätherischen Ölen
                </ProductHeading>
                <StaticImage
                  src="../../assets/images/products/cbd-cremes/naturecan.png"
                  alt="Naturecan CBD Joint Balsam"
                  layout="constrained"
                  placeholder="blurred"
                  width={250}
                />
                <p>💪 Hilft bei Sport </p>
                <p>🌳 Bio Hanf</p>
                <p>🌶️ Variationen: Chili, Zaubernuss, Zitrone & Ingwer</p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={4} desktop>
                  Naturecan CBD Salbe für den Körper: 4 Sterne bei
                  Vergleichstest mit Bio-Hanf und ätherischen Ölen
                </ProductHeading>
                <p>
                  Die{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=270410&slotId=98699&param0=https%3A%2F%2Fwww.naturecan.de%2Fcollections%2Fcbd-salbe%2Fproducts%2Fcbd-salbe-fuer-gelenke"
                    category="cbd-cremes"
                    action="naturecan"
                    label="text"
                  >
                    CBD Salbe für den Körper von Naturecan
                    <img
                      src="https://t.adcell.com/p/view?promoId=270410&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </TrackedLink>{" "}
                  hat im Vergleichstest eine Bewertung von 4 Sternen erhalten
                  und ist damit knapp hinter dem CBD Creme Testsieger. Die
                  Maximalbewertung im Test liegt bei 5 Sternen.
                </p>
                <p>
                  Die Packung der CBD Salbe ist vergleichsweise größer mit einem{" "}
                  <strong>Inhalt von 300 ml</strong> und hat einen CBD Gehalt
                  von 500 mg pro Packung. Der Wirkgehalt des CBD wurde u.a.
                  durch den{" "}
                  <a
                    href="https://www.tuvsud.com/de-de"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    TÜV Süd
                  </a>{" "}
                  geprüft. Die subjektive Wahrnehmung der Wirkung der CBD Salbe
                  ist gut. Zusätze im Produkt sind{" "}
                  <strong>Menthol, Beinwell, Kampfer und Ätherische Öle</strong>
                  , die die Wirkung der CBD noch verstärken und die Salbe zu
                  einem wirksamen und natürlichen Mittel für die Körperanwendung
                  machen.
                </p>
                <p>
                  Die CBD Salbe ist für <strong>normale Hauttypen</strong>{" "}
                  geeignet. Es enthält{" "}
                  <strong>keine Duftstoffe, Farbstoffe oder Parabene</strong>{" "}
                  und ist sowohl vegetarisch als auch vegan.{" "}
                  <strong>Dermatologische Tests liegen jedoch nicht vor</strong>
                  . Es zieht schnell in die Haut ein und fettet nicht.
                </p>
                <p>
                  Dieses <strong>Produkt kostet 37,99 €</strong> und ist fast
                  genauso teuer wie unser Testsieger von Hanfgeflüster. Eine
                  Besonderheit bei dieser CBD Salbe ist, dass die{" "}
                  <strong>CBD Salbe Bio-Hanf enthält</strong>. Hanf hat viele
                  positive Eigenschaften für die Haut und kann besonders beim
                  Sport hilfreich sein. Die CBD Salbe von Naturecan in unserem
                  Test ist eine gute Wahl und unterscheidet sich leicht von dem
                  Testsieger für CBD Salben und Cremes. Die CBD Salbe gibt es
                  zudem in verschiedenen Variationen wie Chili, Zaubernuss,
                  Zitrone und Ingwer.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://amzn.to/3s9kmva"
                    width={18}
                    category="cbd-cremes"
                    action="amazon"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block id="wobecare">
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={3.5}>
                  Überblick: Wobecare CBD Aktiv Creme - Günstiger Preis &
                  Durchschnittliche Qualität
                </ProductHeading>
                <StaticImage
                  src="../../assets/images/products/cbd-cremes/wobecare.png"
                  alt="Wobecare CBD Aktiv Creme"
                  layout="constrained"
                  placeholder="blurred"
                  width={250}
                />
                <p>💧 Kühlt und entspannt</p>
                <p>📺 Bekannte CBD Salbe</p>
                <p>🌎 Ökologische Verpackung</p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={3.5} desktop>
                  Überblick: Wobecare CBD Aktiv Creme - Günstiger Preis &
                  Durchschnittliche Qualität
                </ProductHeading>
                <p>
                  Die
                  <TrackedLink
                    href="https://www.shop-apotheke.at/arzneimittel/D16818814/wobecare-cbd-aktiv-creme.htm"
                    category="cbd-cremes"
                    action="wobecare"
                    label="text"
                  >
                    Wobecare CBD Aktiv Creme
                  </TrackedLink>{" "}
                  ist eine bekannte CBD-Creme aus der Apotheke. Im Vergleich
                  erhält sie 3,5 Sterne. Der Anbieter der Creme ist die Mucos
                  Pharma GmbH aus Deutschland.
                </p>
                <p>
                  Die Packungsgröße der Wobecare CBD Aktiv Creme beträgt 100 ml.
                  Die <strong>CBD-Konzentration</strong> in einer Packung liegt{" "}
                  <strong>wie bei den anderen CBD Produkten bei 500 mg</strong>{" "}
                  und unterscheidet sich damit nicht. Es gibt allerdings{" "}
                  <strong>
                    keine Informationen darüber, ob das CBD-Gehalt durch
                    unabhängige Labore analysiert wurde
                  </strong>
                  . Laut subjektiver Wahrnehmung der Testanwender wirkt die
                  Creme gut. Die Creme enthält zusätzliche Inhaltsstoffe wie
                  Arnika, Beinwell, Menthol, Kampfer, Aloe Vera und Brokkoliöl.
                </p>
                <p>
                  Die Wobecare CBD Aktiv Creme ist für{" "}
                  <strong>normale Haut</strong> geeignet und wurde{" "}
                  <strong>dermatologisch getestet</strong>. Sie zieht ebenfalls
                  schnell ein und fettet nicht. Die Creme enthält{" "}
                  <strong>keine Duftstoffe, Farbstoffe oder Parabene</strong>.
                  Außerdem ist sie sowohl für Vegetarier als auch Veganer
                  geeignet. Der Hersteller verwendet zusätzlich eine ökologische
                  Verpackung.
                </p>
                <p>
                  Bei einer{" "}
                  <strong>durchschnittlichen Qualität mit 3,5 Sternen</strong>{" "}
                  ist die CBD Creme Wobecare sehr günstig in Apotheken zu
                  kaufen. Der Preis liegt bei 24,95 € und kann sich von Apotheke
                  zu Apotheke unterscheiden. Eine Besonderheit der Creme ist,
                  dass sie nicht nur pflegt, sondern auch kühlt und entspannt.
                </p>
                <p>
                  Alles in allem sind die 3,5 Sterne für die Wobecare CBD Aktiv
                  Creme gerechtfertigt und gut begründet, wenn man sie mit dem
                  Testsieger vergleicht.
                </p>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block id="cbdsfinest">
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={3}>
                  CBD Salbe von CBDs Finest: Eine durchschnittliche CBD-Salbe
                  mit starkem CBD Gehalt
                </ProductHeading>
                <StaticImage
                  src="../../assets/images/products/cbd-cremes/cbds-finest.png"
                  alt="CBD's Finest Liposomale PEA Salbe"
                  layout="constrained"
                  placeholder="blurred"
                  width={250}
                />
                <p>🌳 Bio Hanf</p>
                <p>🔋 Hohe CBD Konzentration</p>
                <p>🧪 Verschiedene CBD Stärken vorhanden</p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={3} desktop>
                  CBD Salbe von CBDs Finest: Eine durchschnittliche CBD-Salbe
                  mit starkem CBD Gehalt
                </ProductHeading>
                <p>
                  Die{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=194887&slotId=98699&param0=https%3A%2F%2Fcbdsfinest.de%2Fprodukt%2Fcbd-salbe-1000-mg%2F"
                    category="cbd-cremes"
                    action="cbdsfinest"
                    label="text"
                  >
                    Liposomale PEA Salbe
                    <img
                      src="https://t.adcell.com/p/view?promoId=194887&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </TrackedLink>{" "}
                  ist eine CBD-Salbe, die von dem Anbieter CBDs Finest angeboten
                  wird. Im Vergleichstest und den gesammelten Erfahrungen hat
                  die Salbe eine solide Bewertung von 3 Sternen erhalten. Die
                  Liposomale PEA Salbe hat sich im Vergleich zu anderen
                  CBD-Salben tendenziell als durchschnittlich geschlagen, weil
                  es <strong>relativ einfach gehalten</strong> ist.
                </p>
                <p>
                  CBDs Finest stellt schließlich eine stark konzentrierte
                  Liposomale PEA Salbe vor, die trotz einer{" "}
                  <strong>Packungsgröße von nur 50 ml</strong> einen{" "}
                  <strong>CBD Gehalt von 500 mg</strong> hat. Durch den Test des
                  CBD Wirkgehalts durch den{" "}
                  <a
                    href="https://www.tuvsud.com/de-de"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    TÜV Süd
                  </a>{" "}
                  wird die Qualität des Produkts bestätigt. Die subjektive
                  Wahrnehmung der Wirkung ist gut. Die Liposomale PEA Salbe
                  besteht aus reinem Cannabidiol der Hanfpflanze{" "}
                  <strong>ohne bekannte Zusätze anderer Pflanzen</strong> wie es
                  bei den meisten CBD Salben anderer Anbieter der Fall ist.
                </p>
                <p>
                  Die Bio-Hanf CBD Salbe ist perfekt für Menschen mit{" "}
                  <strong>normaler Haut</strong>. Es{" "}
                  <strong>
                    enthält keine Duftstoffe, Farbstoffe oder Parabene
                  </strong>{" "}
                  und ist daher besonders sanft zur Haut. Es zieht schnell ein
                  und fettet nicht, was es zu einer praktischen Wahl für den
                  täglichen Gebrauch macht. Das Naturprodukt ist zudem
                  vegetarisch und vegan, da es aus{" "}
                  <strong>reinem Bio-Hanf</strong> hergestellt wird.
                </p>
                <p>
                  Das Sortiment an CBD-Salben enthält eine noch stärke Variante
                  für alle, die nach einer noch stärkeren Dosierung suchen.
                </p>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block id="weedm">
          <Heading2 id="geringe-empfehlung" block>
            CBD Cremes und Salben Testbericht 2023
          </Heading2>
          <Heading2 id="geringe-empfehlung" block small>
            Geringe bzw. keine CBD Salben/Cremes Empfehlung
          </Heading2>
          <WinnerBox align="stretch" red>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" red>
              <ContentWrapper>
                <ProductHeading rating={1}>
                  CBD Creme mit schlechten Erfahrungen und enttäuschender
                  Bewertung
                </ProductHeading>
                <StaticImage
                  src="../../assets/images/products/cbd-cremes/weedm.png"
                  alt="weedM CBD Creme"
                  layout="constrained"
                  placeholder="blurred"
                  width={250}
                />
                <p>❌ Schlechte CBD Wirkung</p>
                <p>❌ Fettet auf der Haut</p>
                <p>❌ Kleine Packung für hohen Preis</p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={1} desktop>
                  CBD Creme mit schlechten Erfahrungen und enttäuschender
                  Bewertung
                </ProductHeading>
                <p>
                  Leider konnte die CBD Creme für den Körper von dem Anbieter
                  weedM aus Österreich nur einen Stern im CBD Kosmetik
                  Vergleichstest erzielen und wurde als{" "}
                  <strong>sehr schlechtes Produkt</strong> bewertet. Vor allem{" "}
                  <strong>enttäuschte Erfahrungen</strong> führten zu diesem
                  Ergebnis.
                </p>
                <p>
                  Eine Packung CBD Creme mit nur 50 ml hat einen{" "}
                  <strong>geringeren Inhalt</strong> als andere auf dem Markt
                  erhältliche Optionen. Das{" "}
                  <strong>CBD Gehalt in einer Packung beträgt 300 mg</strong>{" "}
                  und ist damit niedriger im Vergleich zur Konkurrenz. Bisher
                  gibt es{" "}
                  <strong>
                    keine veröffentlichten Tests von unabhängigen Prüflaboren
                  </strong>{" "}
                  über die Wirkstärke des CBDs. Leider berichten ebenso viele
                  Testerinnen und Tester, dass sie{" "}
                  <strong>keine spürbare Wirkung</strong> bemerkt haben. Die
                  gemachten Erfahrungen bei dieser CBD Creme bzw. Salbe waren
                  faktisch schlecht. Es ist nicht bekannt, ob weitere Zusätze
                  wie Menthol oder ätherische Öle enthalten sind.
                </p>
                <p>
                  Die CBD Creme von weedM ist absolut kein Sieger im Test für
                  sehr gute CBD Salben und Cremes für den Körper. Die Creme ist
                  zudem leider <strong>nicht für Allergiker geeignet</strong>{" "}
                  und wurde auch <strong>nicht dermatologisch getestet</strong>.
                  Obwohl der Anbieter eine hochwertige Creme verspricht, zieht
                  sie nicht schnell ein und fettet auf der Haut. Außerdem
                  enthält sie Duftstoffe, Farbstoffe und Parabene, was{" "}
                  <strong>für empfindliche Haut ungünstig</strong> sein kann.
                </p>
                <p>
                  Wir raten ausdrücklich von dem Kauf der CBD Creme bei den
                  gemachten Erfahrungen ab.
                </p>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
      </Container>
      <BackgroundBeige>
        <Container>
          <Block mt={40}>
            <DivHelper center>
              <Heading2 id="cbd-experte">CBD Experte</Heading2>
            </DivHelper>
            <Row>
              <Col md={6}>
                <Quote>
                  &bdquo;Hallo, ich bin Tim. Neben den{" "}
                  <Link to="/vergleiche/cbd-fruchtgummis/">
                    CBD Fruchtgummis
                  </Link>{" "}
                  habe ich auch den Vergleichstest für CBD Salben und Cremes für
                  den Körper geleitet, um Dir bei der Wahl des richtigen
                  Produkts zu helfen. Informiere dich und habe viel Spaß
                  dabei!&ldquo;
                </Quote>
                <SeparatorLine small />
                <Row align="center">
                  <Col xs={4} md={5} lg={4} xl={5}>
                    <Quote>Tim Hasenbank (33)</Quote>
                  </Col>
                  <Col xs={8} md={7} lg={8} xl={7}>
                    <ImageWrapper>
                      <StaticImage
                        src="../../assets/images/team/tim-hasenbank.jpg"
                        placeholder="blurred"
                        layout="constrained"
                        width={300}
                        alt="Tim Hasenbank (CBD Experte)"
                        style={{ borderRadius: "50%" }}
                      />
                    </ImageWrapper>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Heading3Margin>Meine persönliche Empfehlung</Heading3Margin>
                <p>
                  Wenn es um CBD Salben und Cremes für den Körper geht, reden
                  Viele von der Wobecare CBD Aktiv Creme. Dabei sind die
                  gemachten Erfahrungen tatsächlich sehr unterschiedlich.
                </p>
                <p>
                  Ich empfehle jedoch den{" "}
                  <strong>
                    <TrackedLink
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fcbd-balm%2F"
                      category="cbd-cremes"
                      action="hanfgefluester"
                      label="expert"
                    >
                      CBD Balsam von Hanfgeflüster
                    </TrackedLink>{" "}
                    als klaren Testsieger
                  </strong>
                  . Der CBD Gehalt des CBD Balsams ist{" "}
                  <strong>vom TÜV Süd zertifiziert</strong> mit 500 mg CBD. Die{" "}
                  <strong>Wirkung ist sehr überzeugend und geprüft</strong>, das
                  zeigen unsere Erfahrungen und die knallharten Fakten im Test.
                </p>
                <p>
                  <strong>Dermatologisch getestet</strong> ist mir bei CBD
                  Salben und Cremes sehr wichtig. Der CBD Balsam von
                  Hanfgeflüster überzeugt auch in dieser Hinsicht. Er{" "}
                  <strong>zieht schnell ein und fettet nicht</strong>, was für
                  ein angenehmes Hautgefühl sorgt.
                </p>
                <p>
                  Last but not least ist der Preis des CBD Balsams von
                  Hanfgeflüster durchschnittlich im Vergleich und{" "}
                  <strong>kostet 37,95 €</strong>. Dafür bietet es aber eine
                  super Qualität.
                </p>
              </Col>
            </Row>
            <RowMargin>
              <Col>
                <h3>Meine 3 wichtigsten Kriterien</h3>
              </Col>
            </RowMargin>
            <RowMargin margin={10}>
              <Col xs={12} xl={4}>
                <h4>1. CBD Gehalt für den Wirkeffekt</h4>
                <p>
                  CBD Gehalt ist bei der Wahl eines CBD-Produkts egal ob{" "}
                  <Link to="/vergleiche/cbd-oel/">Öl</Link>,{" "}
                  <Link to="/vergleiche/cbd-fruchtgummis/">Fruchtgummis</Link>,{" "}
                  <Link to="/vergleiche/cbd-gleitgel/">Gleitgele</Link> oder
                  Salben das wichtigste Kriterium.{" "}
                  <strong>
                    Je höher der CBD Gehalt, desto größer ist die Chance auf
                    einen starken Wirkeffekt
                  </strong>
                  . Deshalb ist es wichtig, bei der Auswahl eines CBD-Produkts
                  auf die Menge an CBD zu achten, um eine optimale Wirkung zu
                  erzielen. Im Fall des CBD Balsams von Hanfgeflüster wird
                  diesem Kriterium voll gerecht. Mit 500 mg CBD pro Balsam ist
                  er eines der stärker dosierten Produkte auf dem Markt. Hierbei
                  handelt es sich um eine{" "}
                  <strong>hochwirksame Formulierung</strong>, die eine schnelle
                  und effektive Wirkung garantiert.
                </p>
              </Col>
              <ColMarginMobile xs={12} xl={4}>
                <h4>2. Dermatologische Testung</h4>
                <p>
                  Dermatologische Tests sind ein wichtiger Faktor bei der
                  Auswahl von CBD Salben und Cremes. Diese Tests garantieren,
                  dass eine CBD Creme oder Salbe nicht nur wirksam, sondern auch{" "}
                  <a href="#vergleichstabelle">hautverträglich</a> ist. Ein
                  Produkt, das als dermatologisch getestet bezeichnet wird,
                  bedeutet, dass es{" "}
                  <strong>von einer fachkundigen Person überprüft</strong>{" "}
                  wurde, um sicherzustellen, dass es sicher für den Einsatz auf
                  der Haut ist. Eine hautverträgliche Creme oder Salbe ist von
                  entscheidender Bedeutung, insbesondere{" "}
                  <strong>für Menschen mit empfindlicher Haut</strong>, die
                  leicht auf bestimmte Inhaltsstoffe reagieren kann. Insgesamt
                  kann man sagen, dass ein dermatologisch getestetes und
                  hautverträgliches Hanfprodukt ein wichtiger Faktor bei der
                  Auswahl ist. Einen ausführlichen medizinischen Beitrag am
                  Beispiel eines anderen CBD Produkts haben wir{" "}
                  <Link to="/ratgeber/cbd-gleitgel/">hier</Link>{" "}
                  zusammengefasst.
                </p>
              </ColMarginMobile>
              <ColMarginMobile xs={12} xl={4}>
                <h4>3. Preis</h4>
                <p>
                  Der Preis ist ein wichtiges Kriterium bei der Wahl von CBD
                  Salben und Cremes für den Körper. Es wäre ein Fehler, ihn zu
                  vernachlässigen, da er oft ein{" "}
                  <strong>Indikator für die Qualität des Produkts</strong> ist.
                  Es gibt starke Unterschiede zwischen den Preisen von Hanf
                  Salben und Cremes, die von verschiedenen Anbietern angeboten
                  werden. Hochwertige Hautpflegeprodukte können oft teurer sein,
                  da sie hochwertigere Inhaltsstoffe und eine bessere
                  Verarbeitung aufweisen. Es ist jedoch möglich, ein{" "}
                  <strong>gutes Preis-Leistungs-Verhältnis zu finden</strong>,
                  indem man verschiedene Anbieter vergleicht. Es ist wichtig zu
                  beachten, dass ein hoher Preis nicht immer ein Indikator für
                  eine höhere Qualität ist. Einige Marken nutzen den hohen
                  Preis, um ihre Marke zu stärken, ohne eine tatsächliche
                  Verbesserung der Produktqualität zu bieten.
                </p>
                {!detailOpen && (
                  <p>
                    <a
                      href="#"
                      onClick={event => {
                        event.preventDefault()
                        setDetailOpen(true)
                      }}
                    >
                      weiterlesen...
                    </a>
                  </p>
                )}
                <CollapseWrapper>
                  <Collapse isOpened={detailOpen}>
                    <p>
                      Es ist daher wichtig, sorgfältig zu recherchieren und{" "}
                      <strong>verschiedene Anbieter zu vergleichen</strong>,
                      bevor man eine Entscheidung trifft. Ein Vergleich
                      verschiedener Anbieter wie{" "}
                      <strong>in unserem Test</strong> kann dazu beitragen, ein
                      gutes Preis-Leistungs-Verhältnis zu finden und
                      sicherzustellen, dass man das beste Produkt für sein Geld
                      erhält und keine schlechten Erfahrungen bei den CBD Cremes
                      macht.
                    </p>
                  </Collapse>
                </CollapseWrapper>
              </ColMarginMobile>
            </RowMargin>
          </Block>
        </Container>
      </BackgroundBeige>
      <Container>
        <Block mt={60}>
          <DivHelper center>
            <Heading2 id="wissenswertes">
              Wissenswertes zu Cremes und Salben
            </Heading2>
          </DivHelper>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="wirkung">
                Wie gut wirkt CBD Creme bzw. Salbe?
              </Heading2>
              <p>
                CBD-Creme und -Salbe sind eine beliebte Wahl. CBD, oder
                Cannabidiol, ist ein{" "}
                <strong>
                  natürlicher Wirkstoff, der aus Hanfpflanzen gewonnen wird
                </strong>
                . Es enthält keine psychoaktiven Wirkstoffe und ist daher{" "}
                <Link to="/ratgeber/cbd-legalitaet/">legal in Deutschland</Link>{" "}
                zu kaufen.
              </p>
              <p>
                Die Verwendung von Hanf-Creme und -Salbe auf der Haut hat einige
                Vorteile. Da es im Vergleich zu{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Ölen</Link> oder{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">Fruchtgummis</Link>{" "}
                über die Haut aufgenommen wird, kann es{" "}
                <strong>direkt an die betroffene Stelle aufgetragen</strong>{" "}
                werden, was eine <strong>schnelle und gezielte Wirkung</strong>{" "}
                ermöglicht.
              </p>
              <p>
                Eine bekannte{" "}
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/30993303/"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Studie (vgl. Palmieri et al. 2019)
                </a>{" "}
                hat gezeigt, dass{" "}
                <strong>CBD entzündungshemmende Wirkungen</strong> hat, was es
                zu einer attraktiven Wahl für viele Menschen macht. Es ist
                wichtig zu beachten, dass Hanf-Creme und -Salbe nicht als Ersatz
                für eine ärztliche Behandlung angesehen werden sollten. Wenn Sie
                an einer medizinischen Bedingung leiden, die behandelt werden
                muss, sollten Sie immer einen Arzt aufsuchen.
              </p>
              <p>
                Folglich kann man sagen, dass CBD-Creme und -Salbe beliebt ist
                und direkt an die betroffene Stelle aufgetragen werden kann, um
                eine schnelle und gezielte Wirkung zu erzielen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12} lg={8}>
              <Heading2 id="wie-wirkt">
                Wie wirkt die CBD Creme bzw. Salbe?
              </Heading2>
              <p>
                CBD-Creme und -Salbe sind eine vielversprechende Option für
                diejenigen, die nach einer{" "}
                <Link to="/ratgeber/cbd-legalitaet/">legalen</Link> und
                natürlichen Lösung für ihre Haut suchen.
              </p>
              <p>
                Die Wirkung von CBD-Creme und -Salbe beruht auf der Fähigkeit
                des CBD, <strong>Rezeptoren auf der Haut zu aktivieren</strong>.
                Diese Rezeptoren sind Teil des{" "}
                <strong>Endocannabinoid-Systems</strong>, das für die
                Regulierung von Schmerzen, Entzündungen und anderen Funktionen
                verantwortlich ist.{" "}
                <Link to="/ratgeber/cbd-gleitgel/">
                  Genaueres medizinisches Hintergrundwissen zu der Wirkweise von
                  Endocannabinoide erfahren Sie in dem Beitrag am Beispiel von
                  CBD Gleitgelen
                </Link>
                . Wenn also CBD auf die Haut aufgetragen wird, kann es die
                Rezeptoren aktivieren und eine Wirkung auslösen, die an Ort und
                Stelle und in den umliegenden Zell- und Gewebeschichten wirksam
                ist.
              </p>
              <Heading2 id="haeufigkeit">
                Wie oft soll man CBD Creme bzw. Salbe verwenden?
              </Heading2>
              <p>
                Wie oft Sie eine Hanf Salbe verwenden sollten, hängt von
                verschiedenen Faktoren ab, wie Ihren individuellen Bedürfnissen
                und dem Zweck, für den Sie das Produkt verwenden. Im Allgemeinen
                empfehlen Experten, CBD Salbe{" "}
                <strong>drei bis vier Mal am Tag</strong> aufzutragen.
              </p>
              <p>
                Es ist ratsam,{" "}
                <strong>
                  mit einer geringen Menge an Hanf Salbe zu beginnen
                </strong>{" "}
                und die Häufigkeit der Verwendung langsam zu erhöhen, bis Sie
                die für Sie optimale Dosierung gefunden haben.
              </p>
            </Col>
            <Col xs={12} sm={10} md={8} lg={4}>
              <DivHelper mtMobile={30}>
                <StaticImage
                  src="../../assets/images/stock/creme-hand.jpg"
                  placeholder="blurred"
                  layout="constrained"
                  width={400}
                  alt="Creme auf Hand auftragen"
                />
              </DivHelper>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="symptome">
                Welche Symptome lassen sich mit einer CBD Creme behandeln?
              </Heading2>
              <p>
                Im Vergleich zu konventionellen Behandlungen werden Testsieger
                CBD-Cremes und -Salben immer beliebter für eine Vielzahl von
                Haut- und Körperbeschwerden. Obwohl sie im Volksmund oft als
                wirksam bei Muskelkater und Verspannungen bekannt sind, haben{" "}
                <strong>publizierte Studien</strong> vor allem die Wirksamkeit
                von CBD bei anderen Symptomen gezeigt. Im nächsten Abschnitt
                wird eine der bekanntesten Studien vorgestellt und näher
                erläutert.
              </p>
            </Col>
          </Row>
        </Block>
      </Container>

      <BackgroundBeige>
        <Container>
          <Block>
            <Row>
              <Col>
                <Heading2 id="klinische-studie">
                  Untersuchung der Wirksamkeit von CBD-angereicherter Salbe bei
                  entzündlichen Hauterkrankungen: Ergebnisse einer klinischen
                  Studie
                </Heading2>
                <p>
                  Eine <strong>klinische Studie</strong> aus dem Jahre 2019
                  untersuchte die therapeutische Wirkung einer
                  CBD-angereicherten Salbe bei Hauterkrankungen. 20 Patienten
                  wurden über einen Zeitraum von 3 Monaten behandelt und die{" "}
                  <strong>Ergebnisse zeigten</strong>, dass die Behandlung mit
                  der CBD-Salbe tatsächlich{" "}
                  <strong>entzündliche Hauterkrankungen verbessern</strong>{" "}
                  kann.
                </p>
                <p>
                  Die Studie berichtete über die{" "}
                  <strong>
                    entzündungshemmenden Wirkungen von CBD auf die Haut
                  </strong>{" "}
                  und stellte fest, dass es ein vielversprechendes Werkzeug zur
                  Verbesserung von entzündlichen Hauterkrankungen sein kann (
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/30993303/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    vgl. Palmieri et al. 2019
                  </a>
                  ). Die Studie zeigte jedoch auch, dass weitere Forschungen
                  erforderlich sind, um die genauen Mechanismen zu verstehen,
                  die hinter den entzündungshemmenden Wirkungen von CBD stehen,
                  und um sicherzustellen, dass es sicher und wirksam{" "}
                  <strong>für den Einsatz bei Hauterkrankungen</strong> ist.
                </p>
                <p>
                  Es ist wichtig zu beachten, dass diese Studie nur eine kleine
                  Stichprobe von 20 Patienten umfasste und dass weitere
                  klinische Studien erforderlich sind, um die Wirksamkeit und
                  Sicherheit von CBD-Salben bei Hauterkrankungen zu untermauern.
                  Trotzdem kann man sagen, dass die{" "}
                  <strong>Ergebnisse dieser Studie vielversprechend</strong>{" "}
                  sind und dass CBD eine mögliche Behandlung für entzündliche
                  Hauterkrankungen sein kann. Dabei können die Erfahrungen bei
                  jedem Individuum unterschiedlich ausfallen.
                </p>
              </Col>
            </Row>
          </Block>
          <Block>
            <Row align="center" justify="center">
              <Col xs={12} lg={8}>
                <Heading2 id="positive-eigenschaften">
                  Was sind positive Eigenschaften von CBD Cremes und Salben?
                </Heading2>
                <p>
                  CBD-Cremes und Salben wie die Testsieger in unserem
                  Produktvergleich sind immer beliebter geworden, da sie{" "}
                  <strong>eine Vielzahl von positiven Eigenschaften</strong>{" "}
                  aufweisen. Hier sind einige der positiven Eigenschaften von
                  CBD-Cremes und -Salben:
                </p>
                <p>
                  <strong>1. Entzündungen verringern</strong>:{" "}
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/30993303/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    Palmieri et al.
                  </a>{" "}
                  haben gezeigt, dass CBD entzündungshemmende Eigenschaften hat,
                  was bedeutet, dass es dazu beitragen kann, Entzündungen in der
                  Haut zu verringern.
                </p>
                <p>
                  <strong>2. Hautprobleme lindern</strong>: CBD-Cremes und
                  Salben können bei Hautproblemen helfen.{" "}
                </p>
                <p>
                  <strong>3. Feuchtigkeit spenden</strong>: Cremes und Salben
                  mit Hanfzusätzen können Ihrer Haut Feuchtigkeit spenden und
                  trockene Haut befeuchten.
                </p>
                <p>
                  <strong>4. Wärmend</strong>: Einige CBD-Cremes und Salben
                  haben eine wohltuende wärmende Wirkung.{" "}
                </p>
                <p>
                  <strong>5. Kühlt</strong>: Andere Hanf Cremes und Salben haben
                  eine kühlende Wirkung.
                </p>
                <p>
                  <strong>6. Entspannt</strong>: Einige Menschen berichten, dass
                  sie durch CBD-Cremes und Salben entspannen können.{" "}
                </p>
                <p>
                  Es ist wichtig zu beachten, dass die Forschung im Bereich CBD
                  noch immer in ihren Anfängen steckt und dass weitere Studien
                  erforderlich sind, um die genauen Wirkmechanismen von
                  CBD-Cremes und -Salben zu verstehen und zu beurteilen.
                  Trotzdem scheinen die positiven Eigenschaften von CBD-Cremes
                  und -Salben vielversprechend zu sein.
                </p>
              </Col>
              <Col xs={12} sm={10} md={8} lg={4}>
                <DivHelper mtMobile={30}>
                  <StaticImage
                    src="../../assets/images/stock/creme-dosierer.jpg"
                    placeholder="blurred"
                    layout="constrained"
                    width={400}
                    alt="Creme Dosierer"
                  />
                </DivHelper>
              </Col>
            </Row>
          </Block>
        </Container>
      </BackgroundBeige>
      <Container>
        <Block>
          <Row>
            <Col>
              <Heading2 id="richtige-creme-finden">
                Wie findet man für sich die richtige CBD Creme bzw. Salbe?
              </Heading2>
              <p>
                Wenn Sie auf der Suche nach der richtigen CBD-Creme oder Salbe
                sind, sollten Sie einige Faktoren berücksichtigen. Hier sind
                einige <strong>Tipps, die Ihnen dabei helfen können</strong>,
                das richtige Produkt für Ihre Bedürfnisse zu finden:
              </p>
              <p>
                1. Stellen Sie sicher, dass das{" "}
                <strong>Produkt getestet</strong> wurde: Es ist wichtig zu
                überprüfen, ob das von Ihnen in Betracht gezogene CBD-Produkt
                getestet wurde und <strong>Erfahrungsberichte</strong>{" "}
                vorliegen.
              </p>
              <p>
                2. Überprüfen Sie die{" "}
                <strong>Menge an CBD in der Salbe oder Creme</strong>: Es ist
                wichtig, die Menge an CBD in der Salbe oder Creme zu kennen, um
                sicherzustellen, dass Sie die für Sie richtige Dosis erhalten.
                Einige Produkte enthalten nur wenig CBD, während andere eine
                höhere Konzentration aufweisen.
              </p>
              <p>
                3. <strong>Vergleichen Sie mehrere Anbieter</strong>: Es lohnt
                sich, verschiedene CBD-Salben und -Cremes von verschiedenen
                Anbietern zu vergleichen, um Unterschiede in Bezug auf die{" "}
                <strong>Inhaltsstoffe</strong>, die{" "}
                <strong>Konzentration von CBD</strong> und den{" "}
                <strong>Preis</strong> zu erkennen.
              </p>
              <p>
                4. Produkte mit einem{" "}
                <strong>Analysenzertifikat von einem Drittanbieter</strong>: Ein
                Analysenzertifikat von einem Testlabor eines Drittanbieters ist
                ein guter Indikator dafür, dass das Produkt von{" "}
                <strong>hoher Qualität</strong> ist. Ein Analysenzertifikat gibt
                Ihnen auch Informationen darüber, wie viel CBD das Produkt
                enthält, und ob es <strong>frei von Verunreinigungen</strong>{" "}
                ist.
              </p>
              <p>
                Alles in allem ist es wichtig, dass Sie bei der Wahl Ihrer
                CBD-Creme oder -Salbe gründlich recherchieren. Das gilt
                grundsätzlich für alle CBD Produkte wie{" "}
                <Link to="/vergleiche/cbd-oel/">das Öl</Link>,{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">die Fruchtgummis</Link>{" "}
                oder <Link to="/vergleiche/cbd-gleitgel/">den Gleitgelen</Link>.
                Vergleichen Sie verschiedene Produkte und überprüfen Sie die
                Inhaltsstoffe und die Menge an CBD. Verlassen Sie sich auf
                Analysenzertifikate von unabhängigen Testlabors, um
                sicherzustellen, dass Sie ein hochwertiges Produkt erhalten. Um
                die Suche grundsätzlich zu vereinfachen, haben wir bereits{" "}
                <a href="#vergleichstabelle">
                  mehrere Anbieter verglichen und einen klaren CBD Creme
                  Testsieger
                </a>{" "}
                ausfindig gemacht.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <DateRight>
                <em>Beitrag veröffentlicht am 08.02.2023.</em>
              </DateRight>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default CbdCremes
